const TurretTmpl = {
    "x": 0,
    "y":0,
    "image":"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAuCAYAAAAcEfjaAAABvUlEQVR4nO2UoZLCMBRF7y4BhplSUoFvZzAIGOoxzGCw/YR6/oF/qEfxD8gaNFVYIlBVfSItiDCs2GmWbgM7OyvWcN1LcvpubpIC/yLG2I0xdjPNvZsWR1GEIAhggtj3xWEYwvM8EBGyLEMcxzel1JsRKLVer5FlmdFuzZIQAq7rAgCI6GcgjmMQEYgISZI8B0qvnPPaWKlK8SjKe6hmKYoi7HY7hGGI2WxWg2spERGm06mpkbkD5xy+7yMIAuMeKkAZ5zPVOiyXS93JpLdHyTwSA4DNZoP9fv90oW3bWK1Wn0C73cZwOESn04EQwmin2+1+ddhutxBCgIjAOYcQAgB07ThO1RIRIY5juK4Lz/PQaDQgpYRlWZBSIkkSnWBl0/P5HOPxGGmaoigKXK9XnE4nJEkCzjmICEwppaHJZKK9ZlmGPM/R6/W0PeDuHBaLBQaDAfI8BwA4jgPLstBsNjEajQB8nroGLMtCnudQSlXSuVwulVoDUkoURVGZlFKi1WpVxvRtPR6P+mu2bQMAiqJAmqY4n891QEqp87/Pvd/v43A46Prr98HYzff92imXb7u85r9+oi+99NIf9AGQ6MmkTkHTxwAAAABJRU5ErkJggg==",
    "size": 0,
    "invisible": true,
    "warmup": 0,
    "canAttackFlyingUnits": true,
    "canAttackLandUnits":   false,
    "canAttackUnderwaterUnits": false,
    "projectile": 0
}
export {TurretTmpl}
